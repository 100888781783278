@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  margin: 0px;
  font: inherit;
  color: rgb(3, 122, 187);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.App {
  text-align: center;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



#whatsapp-widget{
  align-items: flex-start !important;
}
#whatsapp-widget .ww-text {
margin-bottom: 0;
}

div#whatsapp-widget a {
text-decoration: none;
color: #555555;
}